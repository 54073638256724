import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps } from "react-router-dom";
import { SchoolLocale } from "../../../locales/localeid";
import { Table } from 'antd-min';
import { SchoolClassModel, SubscriptionType, CurriculumType } from '../../../service/class/index';
import {withRouter, GLGlobal, connect, PathConfig as CommonPath, GLUtil} from 'gl-commonui';
import { TableProps } from 'antd/lib/table';
import { SecondaryTitle } from '../../../components';
import { StateType } from '@app/states';
import { getLicenseHistoryBy, getLicense, setFutureAnnualOuter } from '../../../states/school/class';
import {subscriptionTypeUsage, DateHelper, ClassHelper, htmlDecode} from '../../../util';
import { PromoteHistory } from './components/promote-history';
import { Adjustments } from './components/adjustments';
import { ColumnLink, WijmoGrid } from "@app/components/grid";
import {PathConfig} from "@app/config/pathconfig";
import {trim} from "lodash";

const { Grid, Column } = WijmoGrid;

interface LicenseHistoryPageProps {
    list: any[]
    total: number
    loading: boolean
    subType
    usage
    curriculumType
    promoteHistories: []
    getLicenseHistoryBy: (d) => void
    getLicense: (d) => void
    setFutureAnnualOuter: (d) => void;
}

interface Student {
    id: string;
    name: string;
    campusId: string | null;
    classId: string | null;
}

interface LicenseHistoryPageState { }

class SchoolClassesTable extends Table<SchoolClassModel> { }

@withRouter
@connect(({ schoolClass: { list, loading, total, license: { curriculumType }, promoteHistories }, school: { current: { subscriptionType: subType, subscriptionTypeUsage: usage } } }: StateType) => ({
    list,
    loading,
    total,
    subType,
    usage,
    curriculumType,
    promoteHistories
}), {
    getLicenseHistoryBy,
    getLicense,
    setFutureAnnualOuter
})
export class LicenseHistoryPage extends Component<RouteComponentProps<any> & LicenseHistoryPageProps, LicenseHistoryPageState> {
    tableLayout: TableProps<SchoolClassModel> = {
        rowKey: 'id',
        bordered: false,
        loading: true,
        // pagination: new PaginationParams(1, 30),
        onChange: (pagination: any) => {
            this.onPagingChange(pagination);
        },
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const { getLicense, match: { params: { schoolId, classId } } } = this.props;
        getLicense({ schoolId, classId });
        this.onPagingChange(this.tableLayout.pagination);

        const {
            match: { params }
        } = this.props;
        //It will obtain the class information that has been used in the breadcrumb.
        this.props.setFutureAnnualOuter(params);
    }
    onPagingChange(pagination) {
        // const page = this.tableLayout.pagination as PaginationParams;
        // page.current = pagination.current ? pagination.current : pagination;
        this.props.getLicenseHistoryBy({
            schoolId: this.props.match.params.schoolId,
            classId: this.props.match.params.classId
            // pagination: page
        })
    }
    getStudentDetailPath(student: Student) {
        if (!student.campusId || !student.classId) {
            return null;
        }
        const { match: { params: { regionId, schoolId } } } = this.props;
        return GLUtil.pathStringify(
            PathConfig.StudentReport,
            {
                regionId,
                schoolId,
                campusId: student.campusId,
                classId: student.classId,
                studentId: student.id
            }
        )
    }
    renderStudentList(students) {
        if (!students || !students.length) {
            return "";
        }
        return `<ul>
            ${students.map((student) => {
                return student.campusId && student.classId ?
                    `<li><a href="${this.getStudentDetailPath(student)}">${student.name} <i>(${student.englishName})</i></a></li>` :
                    `<li>${student.name} <i>(${student.englishName})</i></li>`
            }).join("\n")}
        </ul>`;
    }
    renderLicenseEditNote(editNoteType, editNote) {
        if (!editNote) {
            return null;
        }
        const students = ClassHelper.getLicenseEditNoteStudentList(editNoteType, editNote);
        const isManyStudents = students && students.length > 1;
        const isOneStudent = students && students.length === 1;
        const studentNameWithDetail = isOneStudent && students[0].campusId && students[0].classId ?
            `<a href=${this.getStudentDetailPath(students[0])}>${students[0].name} <i>(${students[0].englishName})</i></a>` :
            isOneStudent && students[0] ? `${students[0].name} <i>(${students[0].englishName})</i>` : null;
        return `
        ${ClassHelper.getLicenseEditNoteLocalization(editNoteType, editNote, studentNameWithDetail)}
        ${isManyStudents ? this.renderStudentList(students) : ""}`;
    }
    getColumns(subType, usage, curriculumType, includedTextbook = true) {
        const fmtMsg = GLGlobal.intl.formatMessage;
        const cols: any[] = [
            {
                title: fmtMsg({ id: SchoolLocale.LicenseHistoryDates }),
                dataIndex: "date",
                width: "1*",
                render: (text, record, index) => {
                    return DateHelper.formatDate2Local(text);
                },
            },
            {
                title: fmtMsg({ id: SchoolLocale.ClassCnt }),
                dataIndex: "studentCountV",
                width: "1*",
            }
        ]

        // if (usage === subscriptionTypeUsage.Standard && licenseType === LicenseType.GrapeSEED) {
        //    if (subType === SubscriptionType.Digital) {
        if (includedTextbook) {
            cols.push({
                title: fmtMsg({ id: SchoolLocale.ClassLicence }),
                dataIndex: "textbookLicensesV",
                width: "1*"
            })
        }
        //      }
        // else if (subType === SubscriptionType.Textbook) {
        cols.push({
            title: fmtMsg({ id: SchoolLocale.ClassDigital }),
            dataIndex: "digitalLicensesV",
            width: "1*"
        })
        //   }
        //   }
        cols.push(
            {
                title: fmtMsg({ id: SchoolLocale.LicenseHistoryNotes }),
                dataIndex: "notes",
                render: (text, record, index) => {
                    return <div dangerouslySetInnerHTML={{__html: record.notesFormatted}}></div>
                },
                width: '*',
                minWidth: 100,
                maxWidth: 300,
                wordWrap: true
            }
        )
        return cols
    }
    renderTitles(curriculumType, subType, usage, { studentCount, digitalLicenses, textbookLicenses }) {
        const fmtMsg = GLGlobal.intl.formatMessage;
        if (!(usage === subscriptionTypeUsage.Standard) || curriculumType === CurriculumType.LittleSEED) {
            return <div>{`(${fmtMsg({ id: SchoolLocale.ClassCnt })}: ${studentCount})`}</div>
        } else {
            if (subType === SubscriptionType.Digital) {
                return <div>{`(${fmtMsg({ id: SchoolLocale.ClassCnt })}: ${studentCount}${textbookLicenses ? `, ${fmtMsg({ id: SchoolLocale.LicenseTextUpper })}: ${textbookLicenses}`: ""})`}</div>
            } else if (subType === SubscriptionType.Textbook) {
                return <div>{`(${fmtMsg({ id: SchoolLocale.ClassCnt })}: ${studentCount}, ${fmtMsg({ id: SchoolLocale.LicenseHistoryDigitalUpper })}: ${digitalLicenses})`}</div>
            }
        }
    }

    formatData(items) {
        return items.map((item) => {
            const textbookNote = this.renderLicenseEditNote(item.textbookLicenseEditNoteType, item.textbookLicenseEditNote);
            const digitalNote = this.renderLicenseEditNote(item.digitalLicenseEditNoteType, item.digitalLicenseEditNote);
            const licenseEditNote = (!!textbookNote && !!digitalNote) ? `${textbookNote}<br/>${digitalNote}` : (textbookNote || digitalNote || "");
            return {
                ...item,
                notesFormatted: licenseEditNote,
                notes: trim(htmlDecode(licenseEditNote)),
            }
        });
    }

    renderContent() {
        const { list, loading, subType, usage, curriculumType, promoteHistories, match: { params: { regionId, classId } } } = this.props;
        const hasTextbook = list[0] && typeof list[0].textbookLicenses === 'number' && list[0].textbookLicenses > 0;
        this.tableLayout.columns = this.getColumns(subType, usage, curriculumType, hasTextbook);
        this.tableLayout.dataSource = list;
        this.tableLayout.loading = loading;
        // (this.tableLayout.pagination as PaginationParams).total = total;
        return (
            <div className='page-content'>
                {this.renderTitles(curriculumType, subType, usage, list[0] || { studentCount: 0, digitalLicenses: 0, textbookLicenses: 0 })}
                <Grid
                    itemsSource={this.formatData(list)}
                    loading={loading}
                    className="page-content__lhgrid"
                    autoRowHeights={true}
                >
                    {
                        this.getColumns(subType, usage, curriculumType, hasTextbook).map(column => {
                            return <Column
                                    header={column.title}
                                    binding={column.dataIndex}
                                    render={column.render}
                                    key={column.key}
                                    wordWrap={column.wordWrap}
                                    maxWidth={column.maxWidth}
                                    width={column.width}
                                    minWidth={column.minWidth}
                                />
                        })
                    }
                </Grid>
                <PromoteHistory
                    promoteHistories={promoteHistories}
                    routeParams={...this.props.match.params}
                />
                <Adjustments regionId={regionId} classId={classId}></Adjustments>
            </div>
        );
    }

    render() {
        return <div className='content-layout class-license-history'>
            <div className='title'><SecondaryTitle title={SchoolLocale.LicenseHistoryTitle} /></div>
            {this.renderContent()}
        </div>
    }
}
