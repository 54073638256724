import React from 'react';
import {SchoolLocale} from '@app/locales/localeid';
import {SchoolClassModel} from '@app/service/class';
import {ClassHelper, DateHelper, fmtMsg, htmlDecode} from '@app/util';
import {FormattedMessage} from 'react-intl';
import {GLUtil} from 'gl-commonui';
import {PathConfig} from '@app/config/pathconfig';
import {Link} from 'react-router-dom';
import {WijmoGrid} from "@app/components";
import {trim} from "lodash";

const { Grid, Column } = WijmoGrid;

export interface ILicenseHistoryProps {
    schoolClass: SchoolClassModel
    license: any[]
    routeParams: any
}
function getStudentDetailPath(student, routeParams) {
    if (!student.campusId || !student.classId) {
        return null;
    }
    const { regionId, schoolId } = routeParams;
    return GLUtil.pathStringify(
        PathConfig.StudentReport,
        {
            regionId,
            schoolId,
            campusId: student.campusId,
            classId: student.classId,
            studentId: student.id
        }
    )
}
function renderStudentList(students, routeParams) {
    if (!students || !students.length) {
        return "";
    }
    return `<ul>
            ${students.map((student) => {
        return student.campusId && student.classId ?
            `<li><a href="${getStudentDetailPath(student, routeParams)}">${student.name} <i>(${student.englishName})</i></a></li>` :
            `<li>${student.name} <i>(${student.englishName})</i></li>`
    }).join("\n")}
        </ul>`;
}

function renderLicenseEditNote(editNoteType, editNote, routeParams) {
    if (!editNote) {
        return null;
    }
    const students = ClassHelper.getLicenseEditNoteStudentList(editNoteType, editNote);
    const isManyStudents = students && students.length > 1;
    const isOneStudent = students && students.length === 1;
    const studentNameWithDetail = isOneStudent && students[0].campusId && students[0].classId ?
        `<a href=${getStudentDetailPath(students[0], routeParams)}>${students[0].name} <i>(${students[0].englishName})</i></a>` :
        isOneStudent && students[0] ? `${students[0].name} <i>(${students[0].englishName})</i>` : null;
    return `
        ${ClassHelper.getLicenseEditNoteLocalization(editNoteType, editNote, studentNameWithDetail)}
        ${isManyStudents ? renderStudentList(students, routeParams) : ""}`;
}
function getColumns(routeParams) {
    const cols: any[] = [
        {
            title: fmtMsg({ id: SchoolLocale.LicenseHistoryDates }),
            dataIndex: "date",
            width: "1*",
            render: (text, record, index) => {
                return DateHelper.formatDate2Local(text);
            }
        },
        {
            title: fmtMsg({ id: SchoolLocale.ClassCnt }),
            dataIndex: "studentCountV",
            width: "1*",
        }
    ]

    // if (usage === subscriptionTypeUsage.Standard && licenseType === LicenseType.GrapeSEED) {
    //    if (subType === SubscriptionType.Digital) {
    cols.push({
        title: fmtMsg({ id: SchoolLocale.ClassLicence }),
        dataIndex: "textbookLicensesV",
        width: "1*"
    })
    //      }
    // else if (subType === SubscriptionType.Textbook) {
    cols.push({
        title: fmtMsg({ id: SchoolLocale.ClassDigital }),
        dataIndex: "digitalLicensesV",
        width: "1*"
    })
    //   }
    //   }
    cols.push(
        {
            title: fmtMsg({ id: SchoolLocale.LicenseHistoryNotes }),
            dataIndex: "notes",
            width: "*",
            render: (text, record, index) => {
                return <div dangerouslySetInnerHTML={{__html: record.notesFormatted}}></div>
            },
            minWidth: 100,
            maxWidth: 300,
            wordWrap: true
        }
    )
    return cols;
}

function getHeader(schoolClass: SchoolClassModel, {regionId, schoolId}) {
    const campusId = schoolClass.campusId;
    const pathStudents = regionId && schoolId && campusId ? GLUtil.pathStringify(PathConfig.Students, { regionId, schoolId, campusId, classId: schoolClass.id }) : null;
    const pathClasses = regionId && schoolId && campusId ? GLUtil.pathStringify(PathConfig.Classes, { regionId, schoolId, campusId }) : null;
    return (
        <div className='class-promote-history-table-header'>
            <div className='class-promote-history-table-header-item' >
                <FormattedMessage id={SchoolLocale.LicenseHistoryClass} />
                {pathStudents && <Link to={pathStudents}>{schoolClass.name}</Link>}
                {!pathStudents && <span>{schoolClass.name}</span>}
            </div>
            <div className='class-promote-history-table-header-item' >
                <FormattedMessage id={SchoolLocale.LicenseHistoryCampus} />
                {pathClasses && <Link to={pathClasses}>{schoolClass.campusName}</Link>}
                {!pathClasses && <span>{schoolClass.campusName}</span>}
            </div>
            {schoolClass.endDate &&
            <div className='class-promote-history-table-header-item' >
                <FormattedMessage id={SchoolLocale.LicenseHistoryEndDate} />
                <span>{DateHelper.formatDate2Local(schoolClass.endDate)}</span>
            </div>
            }
        </div>
    )
}

function formatData(items, routeParams) {
    return items.map((item) => {
        const textbookNote = renderLicenseEditNote(item.textbookLicenseEditNoteType, item.textbookLicenseEditNote, routeParams);
        const digitalNote = renderLicenseEditNote(item.digitalLicenseEditNoteType, item.digitalLicenseEditNote, routeParams);
        const licenseEditNote = (!!textbookNote && !!digitalNote) ? `${textbookNote}<br/>${digitalNote}` : (textbookNote || digitalNote || "");
        return {
            ...item,
            notesFormatted: licenseEditNote,
            notes: trim(htmlDecode(licenseEditNote)),
        }
    });
}

export const LicenseHistory = (props: ILicenseHistoryProps) => {
    const { schoolClass, license, routeParams } = props;
    return (
        <div className='class-promote-history-item-grid'>
            {getHeader(schoolClass, {...routeParams})}
            <Grid
                itemsSource={formatData(license, {...routeParams})}
                pagination={false}
                className='class-promote-history-item-data'
                autoRowHeights={true}
            >
                {
                    getColumns(routeParams).map(column => {
                        return <Column
                            header={column.title}
                            binding={column.dataIndex}
                            render={column.render}
                            wordWrap={column.wordWrap}
                            maxWidth={column.maxWidth}
                            width={column.width}
                            minWidth={column.minWidth}
                        />
                    })
                }
            </Grid>
        </div>
    )
}
