import React, { Component } from 'react';
import { SchoolLocale } from '@app/locales/localeid';
import { SchoolClassPromoteHistoryModel } from '@app/service/class';
import { fmtMsg, DateHelper, CommonHelper } from '@app/util';
import { FormattedMessage } from 'react-intl';
import { LicenseHistory } from './license-history';

export interface IPromoteHistoryProps {
    promoteHistories: SchoolClassPromoteHistoryModel[]
    routeParams: any
}

export const PromoteHistory = (props: IPromoteHistoryProps) => {
    const { promoteHistories, routeParams } = props;
    return (
        promoteHistories && promoteHistories.length > 0 &&
            <div className='class-promote-history'>
                <div className='class-promote-history-title'>
                    <FormattedMessage id={SchoolLocale.LicenseHistoryPromote}/>
                </div>
                {
                    promoteHistories.map((promoteHistory, index) => {
                        return (
                            <div className='class-promote-history-item' key={index}>
                                {
                                    index > 0 && CommonHelper.getRangeArray(0, index - 1).map(ahead => {
                                        return (
                                            <div className='class-promote-history-item-ahead' key={`${index}${ahead}`}>
                                                <div className='class-promote-history-item-ahead-top ant-table-title'></div>
                                                <div className='class-promote-history-item-ahead-bottom'></div>
                                            </div>
                                        )
                                    })
                                }
                                <LicenseHistory
                                    schoolClass = {promoteHistory.schoolClass}
                                    license = {promoteHistory.licenseHistory}
                                    routeParams = {routeParams}
                                />
                            </div>
                        )
                    })
                }
            </div>
    )
}
