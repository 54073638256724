import React, { useState, useEffect } from "react";
import { GLGlobal } from "gl-commonui";
import { Card, Row, Modal, message } from "antd-min";
import { useService } from "@app/hooks";
import { ISchoolLicenseService, AdjustmentGroupModel, AdjustmentDetailGroupModel, UpdateAdjustmentResultCode } from "@app/service/admin/license";
import { TYPES, GSAdminAction, fmtMsg, DateHelper } from "@app/util";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import { AdjustmentDetails } from "./adjustment-details";
import "./adjustments.less";

interface AdjustmentProps {
    regionId: string;
    classId: string;
}

export const Adjustments = ({ regionId, classId }: AdjustmentProps) => {
    const [loading, setLoading] = useState(false);
    const [hasUpdatePermission, setHasUpdatePermission] = useState(false);
    const [adjustments, setAdjustments] = useState([] as AdjustmentGroupModel[]);
    const service = useService<ISchoolLicenseService>(TYPES.ISchoolLicenseService);

    useEffect(() => {
        setHasUpdatePermission(GLGlobal.isActionValid(GSAdminAction.ClassManualAdjustment));
        getAdjustmentDetails();
    }, []);

    const getAdjustmentDetails = () => {
        const params = { regionId, schoolClassId: classId };
        setLoading(true);

        return service.getAdjustmentDetails(params)
            .then(response => {
                setAdjustments(response);
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            });
    }

    const removeAdjustment = (adjustment: AdjustmentDetailGroupModel, callback: () => void) => {
        return service.deleteAdjustment(adjustment, { regionId, schoolClassId: classId })
            .then(result => {
                if (result.processResults.find(res => res === UpdateAdjustmentResultCode.AdjustmentUpdated)) {
                    message.success(fmtMsg({ id: SchoolLocale.AdjustmentRemovedSuccessMsg }));
                }
                if (result.processResults.find(res => res === UpdateAdjustmentResultCode.InvoiceNotFound)) {
                    /** Reasons:
                     * 1. Class created after invoice generation.
                     * 2. Billing day is pushed back before invoice generation to a date before today.
                     * 3. Billing start date is pushed after billing date.
                     * 4. Rare: Timezone is changed (only in case when billing start day and billing date are same)
                     * 5. More rare: Invoice did not ran for the school class for some wierd reason. 
                     */
                    message.warning("Adjustment made for the current cycle would not alter the current invoice because it is unavailable for this class.");
                }
                getAdjustmentDetails();
                callback();
            })
            .catch(() => {
                message.error(fmtMsg({ id: SchoolLocale.AdjustmentRemovedFailureMsg }));
                callback();
            });
    }

    const removeCallback = (modal: { destroy: () => void; }) => {
        modal && modal.destroy && modal.destroy();
    }

    const handleRemove = (adjustment: AdjustmentDetailGroupModel) => {
        const modal = Modal.confirm({
            className: "adjustments__confirm",
            okText: fmtMsg({ id: GSAdminLocale.CommonButtonOk }),
            cancelText: fmtMsg({ id: GSAdminLocale.ButtonCancel }),
            onOk: () => {
                modal.update({
                    okButtonProps: { loading: true, className: "adjustments__confirm__btn adjustments__confirm__btn--submit" }
                });
                return removeAdjustment(adjustment, removeCallback.bind(null, modal));
            },
            okButtonProps: { className: "adjustments__confirm__btn adjustments__confirm__btn--submit" },
            cancelButtonProps: { className: "adjustments__confirm__btn adjustments__confirm__btn--cancel" },
            onCancel: () => {
                modal && modal.destroy && modal.destroy();
            },
            content: fmtMsg({ id: SchoolLocale.RemoveAdjustmentConfirmText }, {
                adjustment: adjustment ? adjustment.adjustment : "",
                user: adjustment ? adjustment.updatedBy : "",
                date: adjustment ? DateHelper.formatDate2Local(adjustment.updateDate) : ""
            }),
        });
    }

    return (<Card className="adjustments">
        <Row>
            <AdjustmentDetails loading={loading} hasUpdatePermisson={hasUpdatePermission} handleRemove={handleRemove} adjustmentDetails={adjustments} />
        </Row>
    </Card>);
}