import React from "react";
import { AdjustmentGroupModel, AdjustmentDetailGroupModel } from "@app/service/admin/license";
import { fmtMsg, DateHelper } from "@app/util";
import { SecondaryTitle, ThirdTitle, Loading } from "@app/components";
import { SchoolLocale, GSAdminLocale } from "@app/locales/localeid";
import { Table, Icon } from "antd-min";
import { ColumnProps } from "antd/lib/table";
import { ColumnLink, WijmoGrid } from "@app/components/grid";
import "./adjustment-details.less";

const { Grid, Column } = WijmoGrid;
interface AdjustmentDetailProps {
    loading: boolean;
    hasUpdatePermisson: boolean;
    handleRemove: (adjustment: AdjustmentDetailGroupModel) => void;
    adjustmentDetails: AdjustmentGroupModel[];
}

export const AdjustmentDetails = ({ handleRemove, adjustmentDetails, loading, hasUpdatePermisson }: AdjustmentDetailProps) => {
    const onRemove = (adjustment: AdjustmentDetailGroupModel) => {
        handleRemove(adjustment);
    }

    const columns: any[] = [
        {
            title: fmtMsg({ id: SchoolLocale.LicenseHistoryAdjustmentCol }),
            dataIndex: "adjustment",
            key: "adjustment",
            width: "15%",
        },
        {
            title: fmtMsg({ id: SchoolLocale.LicenseHistoryUpdateDateCol }),
            dataIndex: "updateDate",
            key: "updateDate",
            width: "15%",
            render: (text) => DateHelper.toLocalDate(text),
        },
        {
            title: fmtMsg({ id: SchoolLocale.SchoolLicenseHistoryAdjustedBy }),
            dataIndex: "updatedBy",
            key: "updatedBy",
            width: "15%"
        },
        {
            title: fmtMsg({ id: SchoolLocale.LicenseHistoryNotes }),
            dataIndex: "note",
            key: "note",
            width: "40%",
        },
    ];

    const getColumns = (cannotModify?: boolean): any[] => {
        const tableColumns = [...columns];

        if (hasUpdatePermisson) {
            tableColumns.push({
                title: fmtMsg({ id: GSAdminLocale.CommonRemove }),
                dataIndex: "id",
                key: "id",
                width: "15%",
                render: (text, record) => {
                    return cannotModify ? <span></span> : <Icon title={fmtMsg({ id: GSAdminLocale.CommonRemove })} onClick={() => onRemove(record)} type="close-circle-o"></Icon>
                }
            });
        }
        return tableColumns;    
    }
    

    if (loading) {
        return <Loading text={fmtMsg({ id: SchoolLocale.LicenseHistoryAdjustmentsLoading })} iconClassName="class-license-history__loader" visible></Loading>
    }

    const adjustmentWithDetails = adjustmentDetails.filter(adjustment => adjustment.details.length);

    return (<div className="class-license-history__adjustment-details">
        <SecondaryTitle title={SchoolLocale.LicenseHistoryAdjustmentDetails} />
        {
            adjustmentWithDetails.length === 0
                ? <div className="ant-table-placeholder">{fmtMsg({ id: SchoolLocale.LicenseHistoryNoDataMsg })}</div>
                : adjustmentWithDetails.map((adjustment: AdjustmentGroupModel) => {
                    return <div key={adjustment.id}>
                        <ThirdTitle className="class-license-history__adjustment-details__header" plain={`${fmtMsg(SchoolLocale.LicenseHistoryBillingDateCol)}: ${DateHelper.formatDate2Local(adjustment.billingDate)}`}></ThirdTitle>
                        <Grid
                            itemsSource={adjustment.details}
                            loading={loading}
                            className="class-license-history__adjustment-details__grid"
                        >
                            {
                                getColumns(adjustment.cannotModify).map(column => {
                                    return <Column 
                                        header={column.title}
                                        binding={column.dataIndex}
                                        key={column.key}
                                        render={column.render}
                                        align={column.align}
                                    />
                                })
                            }
                        </Grid>
                    </div>
                })
        }
    </div>);
}